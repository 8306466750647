import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ActiveProjects from './components/ActiveProjects';
import ProjectDetail from './components/ProjectDetail';
import VideoAssetsFolders from './components/VideoAssetsFolders';
import ProjectAssets from './components/ProjectAssets';
import VideoDevelopmentDetail from './components/VideoDevelopmentDetail';
import VideoReview from './components/VideoReview';
import SubFoldersAssets from './components/SubFoldersAssets';
import EditorInvite from './components/EditorInvite';
import InnerSubFoldersAssets from './components/InnerSubFolderAssets';
import InnerProjectAssets from './components/InnerProjectAssets';

const Projects = ({ component, ...rest }) => {
  const { path } = rest.match;

  return (
    <Row>
      <Col>
        <Switch>
          <Route
            exact
            path={`${path}/project-list`}
            component={ActiveProjects}
          />
          <Route
            exact
            path={`${path}/project-detail/:projectId`}
            component={VideoDevelopmentDetail}
          />
          <Route
            path={`${path}/active-projects/project-detail/:projectId`}
            component={ProjectDetail}
          />
          <Route
            exact
            path={`${path}/:projectId/folders/editor-invite`}
            component={EditorInvite}
          />
          <Route
            path={`${path}/:projectId/folders/:feedbackStatus?`}
            exact
            component={VideoAssetsFolders}
          />
          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders/:child/innerSubFolder/:name`}
            component={InnerProjectAssets}
          />
          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders/:child/innerSubFolder`}
            component={InnerSubFoldersAssets}
          />
          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders/:child`}
            component={ProjectAssets}
          />
          <Route
            path={`${path}/:projectId/folders/:parentName/:parent/subFolders`}
            component={SubFoldersAssets}
          />
          <Route
            path={`${path}/:projectId/folders/:parentName/:parent`}
            component={ProjectAssets}
          />
          <Route
            exact
            path={`${path}/video-review/:projectId/:folderId`}
            component={VideoReview}
          />
        </Switch>
      </Col>
    </Row>
  );
};
export default Projects;
