import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FolderComponent from 'common/FolderComponent';
import NoData from 'common/NoData';
import { useQuery } from '@apollo/client';
import {
  GET_AWS_FOLDERS,
  GET_UPDATED_CUSTOM_FOLDERS,
} from '../../../ProjectsGraphQL';
import {
  FOLDERS_FILES_TOGETHER,
  FOLDERS_WITH_CHILDREN,
} from 'constants/appConstants';
import InnerProjectAssets from '../../InnerProjectAssets';

const SubFolders = ({
  directoryName,
  subDirectoryName,
  children,
  projectId,
  portalSlug,
  parentNameLink,
  parentLink,
  hasFilesInFolderListing,
  child,
}) => {
  const history = useHistory();

  const param = useParams();

  const goBackToList = () => {
    history.push(`/pm-portal/projects/${projectId}/folders`);
  };

  let folders = [];

  const { data, subscribeToMore, refetch } = useQuery(GET_AWS_FOLDERS, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const subscribe = subscribeToMore({
      document: GET_UPDATED_CUSTOM_FOLDERS,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData?.data?.getUpdatedCustomFolders) {
          refetch();
        }
        return prev;
      },
    });

    return () => subscribe();
  }, []);

  if (data) {
    let targetFolder;
    let foldersToSearch;
    const arrayValue = subDirectoryName === 'aerialFootage' ? 0 : 1;
    if (param.parent) {
      foldersToSearch = find(data.getAwsFolders[0].children, [
        'name',
        FOLDERS_WITH_CHILDREN[param.parentName],
      ])?.children;
    }

    if (foldersToSearch) {
      targetFolder = find(foldersToSearch, [
        'name',
        FOLDERS_WITH_CHILDREN[param.parent],
      ]);
    }
    if (targetFolder) {
      folders = targetFolder.children[arrayValue].children;
    }
  }
  const renderChildrenFolders = () => {
    if (!folders || !folders.length) {
      return (
        <Col md={12}>
          <NoData customMessage="No data available." />
        </Col>
      );
    }

    return folders.map(({ parent, name }) => {
      if (
        !hasFilesInFolderListing ||
        (hasFilesInFolderListing &&
          FOLDERS_FILES_TOGETHER[param.parent]?.includes(parent))
      ) {
        return (
          <Col md={3} className="mb-3" key={parent}>
            <Link
              to={{
                pathname: `/${portalSlug}/projects/${projectId}/folders/${parentNameLink}/${parentLink}/subFolders/${child}/innerSubFolder/${name}`,
                state: {
                  directoryName,
                  subDirectoryName: parent,
                },
              }}
              className="text-decoration-none text-body"
            >
              <FolderComponent folderName={parent} />
            </Link>
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <>
      <div className="video-review-folder">
        <Col md={6} className="px-0">
          <Button
            color="link"
            className="back-btn color-inherit px-0 folder-back-btn"
            onClick={goBackToList}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
            {child}
          </Button>
        </Col>
        {hasFilesInFolderListing && (
          <InnerProjectAssets
            hasFilesInFolderListing={hasFilesInFolderListing}
          />
        )}
        <Row>{renderChildrenFolders()}</Row>
      </div>
    </>
  );
};

export default SubFolders;
