import React, { useState } from 'react';
import {
  faEnvelope,
  faPhoneAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useQuery } from '@apollo/client';
//
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import { PILOT_PROJECT_DETAILS } from 'pages/Pilot/PilotPortalGraphQL';
import AdditionalNotes from './AdditionalNotes';
import FlightDetails from './FlightDetails';
import ProjectInstruction from './ProjectInstruction';
import ProjectPlanDetails from './ProjectPlanDetails';

const ProjectDetail = ({
  selectProject,
  isProjectAccepted,
  currentProject,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const { loading, error, data } = useQuery(PILOT_PROJECT_DETAILS, {
    variables: {
      projectId: parseInt(selectProject, 10),
      dataSource: currentProject.dataSource,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <PageLoader />;
  if (error) return <NoData customMessage={error.message} />;

  if (data && data.pilotProjectDetails) {
    const {
      pilotProjectDetails: {
        projectName,
        propertyAddress,
        googleLink,
        interiorContactDetails,
        projectManagerContactDetails: { fullName, phone, email },
        assetType,
        id,
        dealMapScreenshot,
        userInstructions,
        projectPlanDetails,
      },
    } = data;

    const renderAssetType = () => {
      if (!assetType.length)
        return <p className="font-size-14 mb-0">Not Specified</p>;

      return assetType.map((value) => {
        return (
          <p className="font-size-14 mb-0" key={value}>
            {value}
          </p>
        );
      });
    };

    const renderGoogleLink = () => {
      if (googleLink) {
        return (
          <a
            href={`${googleLink}?q=${propertyAddress}`}
            rel="noreferrer"
            target="_blank"
            className="btn-sm btn-primary font-size-13 px-3 btn btn-secondary"
          >
            Map location
          </a>
        );
      }
      return <>Not Specified</>;
    };

    const OpenImageModal = ({ modal, toggle }) => {
      return (
        <>
          <Modal isOpen={modal} toggle={toggle} centered className="modal-xl">
            <ModalHeader toggle={toggle}>Image</ModalHeader>
            <ModalBody>
              <img src={dealMapScreenshot} alt="map view" className="w-100" />
            </ModalBody>
          </Modal>
        </>
      );
    };

    return (
      <Row>
        <Col sm="8">
          <Card body className="mt-4 border-radius-8px border-0 shadow">
            <CardTitle tag="h5" className="font-size-16">
              {projectName}
            </CardTitle>
            <Row>
              <Col md={4}>
                <div className="mb-2">
                  <h3 className="font-size-14">Property Address</h3>
                  <p className="font-size-14 mb-0">{propertyAddress}</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-2">
                  <h3 className="font-size-14">Google Map Link</h3>
                  {renderGoogleLink()}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-2">
                  <h3 className="font-size-14">Asset Type</h3>
                  {renderAssetType()}
                </div>
              </Col>
              {/* <Col md={3}>
                <div className="mb-2">
                  <h3 className="font-size-14">Asset Class</h3>
                  <p className="font-size-14 mb-0">{assetClass}</p>
                </div>
              </Col> */}
            </Row>
          </Card>
          <Row>
            <Col sm="6">
              <Card body className="mt-4 border-radius-8px border-0 shadow">
                <CardTitle tag="h5" className="font-size-16">
                  PROJECT MANAGER
                </CardTitle>

                <ul className="list-inline mb-0">
                  <li className="mb-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      color="#565082"
                      className="mr-2"
                    />
                    {fullName || 'N/A'}
                  </li>
                  <li className="mb-2">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      color="#565082"
                      className="mr-2"
                    />
                    {phone || 'N/A'}
                  </li>
                  <li className="mb-2">
                    <Row>
                      <Col md={8}>
                        <div>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            color="#565082"
                            className="mr-2"
                          />
                          {email || 'N/A'}
                        </div>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </Card>
            </Col>
            <Col sm="6">
              <Card
                body
                className={
                  Object.keys(interiorContactDetails).length !== 0
                    ? 'mt-4 border-radius-8px border-0 shadow'
                    : 'mt-4 border-radius-8px border-0 shadow opacity-2'
                }
              >
                <CardTitle tag="h5" className="font-size-16">
                  CONTACT FOR INTERIOR ACCESS
                </CardTitle>
                <ul className="list-inline mb-0">
                  <li className="mb-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      color="#565082"
                      className="mr-2"
                    />
                    {interiorContactDetails.name || 'N/A'}
                  </li>
                  <li className="mb-2">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      color="#565082"
                      className="mr-2"
                    />
                    {interiorContactDetails.phone || 'N/A'}
                  </li>
                  <li className="mb-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      color="#565082"
                      className="mr-2"
                    />
                    {interiorContactDetails.email || 'N/A'}
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>
          <Card body className="mt-4 border-radius-8px border-0 shadow">
            <CardTitle tag="h5" className="font-size-16">
              ADDITIONAL NOTES
            </CardTitle>
            <CardBody className="p-0">
              <AdditionalNotes selectProject={selectProject} />
            </CardBody>
          </Card>

          <ProjectInstruction userInstructions={userInstructions} />
        </Col>
        <Col sm="4">
          <ProjectPlanDetails projectPlanDetails={projectPlanDetails} />
          <FlightDetails projectId={id} isProjectAccepted={isProjectAccepted} />
          <Card body className="mt-4 border-radius-8px border-0 shadow">
            <CardTitle tag="h5" className="font-size-16">
              IMAGE
            </CardTitle>
            <CardBody className="p-0">
              {dealMapScreenshot ? (
                <Button onClick={toggle} className="p-0 border-0">
                  <img
                    src={dealMapScreenshot}
                    className="w-100"
                    alt="Property Boundary Not Found"
                  />
                </Button>
              ) : (
                <div className="text-center">
                  Property Boundary Image Not Found
                </div>
              )}
            </CardBody>
          </Card>
          <OpenImageModal modal={modal} toggle={toggle} />
        </Col>
      </Row>
    );
  }

  return <NoData />;
};

export default ProjectDetail;
