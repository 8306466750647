import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import RBFP from 'hoc/RBFP';
import { VIEW } from 'constants/permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FolderComponent from 'common/FolderComponent';
import NoData from 'common/NoData';
import { FOLDERS_FILES_TOGETHER } from 'constants/appConstants';
import ProjectAssets from '../../ProjectAssets';

const SubFolders = ({
  directoryName,
  subDirectoryName,
  children,
  projectId,
  portalSlug,
  parentNameLink,
  parentLink,
  hasFilesInFolderListing,
}) => {
  const history = useHistory();

  const param = useParams();

  const goBackToList = () => {
    history.push(`/editor-portal/projects/${projectId}/folders`);
  };

  const renderChildrenFolders = () => {
    if (!children || !children.length) {
      return (
        <Col md={12}>
          <NoData customMessage="No data available." />
        </Col>
      );
    }

    const getInnerSubFolder = (parent) => {
      if (parent === 'Aerial Footage' || parent === 'Aerial Photos') {
        return 'innerSubFolder';
      }
      return '';
    };

    return children.map(({ parent, name }) => {
      if (
        !hasFilesInFolderListing ||
        (hasFilesInFolderListing &&
          FOLDERS_FILES_TOGETHER[param?.parent]?.includes(parent))
      ) {
        return (
          <RBFP
            key={name}
            permissionType={VIEW}
            subDirectoryName={name}
            directoryName={directoryName}
          >
            <Col md={3} className="mb-3" key={parent}>
              <Link
                to={{
                  pathname: `/${portalSlug}/projects/${projectId}/folders/${parentNameLink}/${parentLink}/subFolders/${parent}/${getInnerSubFolder(
                    parent
                  )}`,

                  state: {
                    directoryName,
                    subDirectoryName: name,
                  },
                }}
                className="text-decoration-none text-body"
              >
                <FolderComponent folderName={parent} />
              </Link>
            </Col>
          </RBFP>
        );
      }
      return null;
    });
  };

  return (
    <>
      <div className="video-review-folder">
        <Col md={6} className="px-0">
          <Button
            color="link"
            className="back-btn color-inherit px-0 folder-back-btn"
            onClick={goBackToList}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
            {parentLink}
          </Button>
        </Col>
        {hasFilesInFolderListing && (
          <ProjectAssets hasFilesInFolderListing={hasFilesInFolderListing} />
        )}
        <Row>{renderChildrenFolders()}</Row>
      </div>
    </>
  );
};

export default SubFolders;
