import React, { useState, useEffect } from 'react';
import { Container, Col, Button } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useQueryParams } from 'pages/Search/hooks';
import Paginator from 'common/Paginator';
import {
  SEARCH_BROKER_ASSETS,
  GET_PENDING_ASSETS,
} from 'pages/Search/CrexiSearchGraphQL';
import PageLoader from 'pages/Search/common/PageLoader';
import { changeSalesFilters } from 'pages/Search/SalesActions';
import SearchResultPlaceholder from './components/SearchResultPlaceholder';
import PropertyItem from './components/PropertyItem';

const LIMIT = 10;

const filterObject = {
  firstName: '',
  lastName: '',
  companyName: '',
  offset: 0,
  page: 1,
};

const PropertyList = ({ onSelect, isPendingOrder }) => {
  const dispatch = useDispatch();
  const [query] = useQueryParams();

  const { searchFilters, eliteAuction } = useSelector(
    (state) => state.salesPortal
  );

  const history = useHistory();
  const [pendingOrder, setPendingOrdert] = useState(
    query.get('pendingOrder') || ''
  );
  const [filters, setFilters] = useState(searchFilters || filterObject);

  useEffect(() => {
    setFilters(searchFilters || filterObject);
    setPendingOrdert(query.get('pendingOrder') || '');
  }, [history.location, searchFilters, eliteAuction]);

  let queryApi = SEARCH_BROKER_ASSETS;
  const variables = filters;

  if (pendingOrder) queryApi = GET_PENDING_ASSETS;

  const { data, loading, error, refetch } = useQuery(queryApi, {
    variables,
    fetchPolicy: pendingOrder ? 'network-only' : 'cache-first',
    // skip: !(variables.firstName || variables.lastName || variables.companyName),
  });

  if (
    !filters.firstName &&
    !filters.lastName &&
    !filters.companyName &&
    !pendingOrder
  ) {
    return <SearchResultPlaceholder message="" />;
  }

  if (loading) return <PageLoader />;
  if (!data) return <SearchResultPlaceholder message="No assets Found!" />;
  if (error) return <div>{error.message}</div>;

  const { assets, totalCount } = data.searchAssets || data.getPendingAssets;
  const pageCount = Math.ceil(totalCount / filters.limit);

  const goToTop = async (filters) => {
    await dispatch(
      changeSalesFilters({
        searchFilters: {
          ...searchFilters,
          ...filters,
        },
      })
    );

    setFilters(filters);
    window.scrollTo(0, 0);
  };

  const handlePageChange = (page) => {
    const { limit } = filters;
    const newOffset = (page - 1) * limit;
    goToTop({ ...filters, LIMIT, offset: newOffset, page });
  };

  const handleGoToFirst = () => {
    goToTop({ ...filters, LIMIT, offset: 0, page: 1 });
  };

  const handleGoToNext = () => {
    const { page, limit } = filters;
    const newPage = page + 1;
    const newOffset = page * limit;
    goToTop({ ...filters, LIMIT, offset: newOffset, page: newPage });
  };

  const handleGoToLast = () => {
    const { limit } = filters;
    const newOffset = (pageCount - 1) * limit;
    goToTop({ ...filters, LIMIT, offset: newOffset, page: pageCount });
  };

  const handleGoToPrevious = () => {
    const { page, limit } = filters;
    const newPage = page - 1;
    const pageNumber = newPage - 1;
    const newOffset = pageNumber * limit;
    goToTop({ ...filters, LIMIT, offset: newOffset, page: newPage });
  };

  const handleOpenDetail = (id, propertyDetail = {}) => {
    window.scrollTo(0, 0);
    onSelect(id, propertyDetail);
  };

  const renderPropertyList = (assets) => {
    return assets?.map((row) => {
      return (
        <PropertyItem
          key={row.id}
          {...row}
          handleOpenDetail={handleOpenDetail}
          pendingOrder={pendingOrder}
          refetch={refetch}
        />
      );
    });
  };

  if (!assets || (assets && !assets.length)) {
    return <SearchResultPlaceholder message="No assets found." />;
  }

  const goToSalesPortal = async () => {
    history.push({
      pathname: '/crexi-search/results',
    });
  };

  return (
    <>
      <Scrollbars
        autoHide
        className="row"
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ width: `calc(100% + 30px)`, height: `calc(100vh - 60px)` }}
      >
        <Col sm="12">
          <div>
            {isPendingOrder && (
              <Button
                color="link"
                className="font-size-14 pl-0 font-weight-bold"
                onClick={goToSalesPortal}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
                Back to Sales Portal
              </Button>
            )}
            <div
              className={`search-result-header ${
                !isPendingOrder ? 'pt-2 pl-4' : ''
              }`}
            >
              <b> {totalCount} Results found.</b>
            </div>
          </div>
          <Container fluid={!isPendingOrder ? 'xl' : 'fluid'} className="p-0">
            <div className="py-2 property-list">
              {renderPropertyList(assets)}
            </div>
            <div className="mb-4 ">
              <Paginator
                offset={filters.offset}
                pageCount={pageCount}
                recordLength={assets.length}
                currentPage={filters.page}
                onPageChange={handlePageChange}
                handleGoToFirst={handleGoToFirst}
                handleGoToNext={handleGoToNext}
                handleGoToLast={handleGoToLast}
                handleGoToPrevious={handleGoToPrevious}
                totalCount={totalCount}
              />
            </div>
          </Container>
        </Col>
      </Scrollbars>
    </>
  );
};

export default PropertyList;
