import React, { useContext, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import FileUploader from 'common/FileUploader';
import { awsFolderAssetsContext } from 'contexts/GetAWSFolderAssets';

const UploadModal = ({
  upload,
  imageryId,
  uploadLocation,
  refetchPilot,
  pilotID,
  forPilotPortal,
  versionSuffix,
  isVideoPortal,
  isCustomShots,
  isVidPitchChangeImage,
}) => {
  const { refetchAssets } = useContext(awsFolderAssetsContext);

  const [uploadFileModal, setUploadFileModal] = useState(false);

  const toggleModal = () => setUploadFileModal(!uploadFileModal);
  return (
    <>
      <Button
        color="primary"
        onClick={toggleModal}
        className="btn-sm px-4 mr-0 mt-2"
      >
        {upload}
      </Button>
      <Modal
        isOpen={uploadFileModal}
        toggle={toggleModal}
        className="modal-lg modal-dialog-centered"
      >
        <ModalBody>
          <div className="">
            <FileUploader
              uploadLocation={uploadLocation}
              refetchAssets={refetchAssets}
              projectId={imageryId}
              forPilotPortal={forPilotPortal}
              refetchPilot={refetchPilot}
              isCustomShots={isCustomShots}
              pilotID={pilotID}
              toggleModal={toggleModal}
              versionSuffix={versionSuffix[uploadLocation]}
              isVideoPortal={isVideoPortal}
              isVidPitchChangeImage={isVidPitchChangeImage}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadModal;
