import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_ASSESTS_DETAILS } from 'pages/Search/CrexiSearchGraphQL';

export const assetDetailContext = React.createContext();

const AssetDetailProvider = ({ children }) => {
  const [assetDetail, setAssetDetail] = useState(null);
  const [assetToEdit, setAssetEdit] = useState({
    editStatus: false,
    assetData: null,
  });
  const [isIVCDisabled, setIsIVCDisabled] = useState(false);
  const [isGalleryLoading, setGalleryLoading] = useState(false);
  const [galleryImage, setGalleryImage] = useState([]);

  const { assetId } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_ASSESTS_DETAILS, {
    variables: {
      assetId: parseInt(assetId, 10),
    },
    fetchPolicy: 'network-only',
    skip: !assetId,
  });

  useEffect(() => {
    if (data) {
      setAssetDetail(data.getAssetDetails);
    }
  }, [data]);

  const handleEditAsset = (editStatus, assetData) => {
    setAssetEdit({
      editStatus,
      assetData,
    });
  };

  return (
    <assetDetailContext.Provider
      value={{
        error,
        loading,
        assetToEdit,
        assetDetail,
        refetchAssetDetail: refetch,
        handleEditAsset,
        isIVCDisabled,
        setIsIVCDisabled,
        isGalleryLoading,
        setGalleryLoading,
        galleryImage,
        setGalleryImage,
      }}
    >
      {children}
    </assetDetailContext.Provider>
  );
};

export default AssetDetailProvider;
